import React, { Component } from "react";
import { signup } from "../auth";
import { Link } from "react-router-dom";
// import SocialLogin from "./SocialLogin";
import { sendMessage } from "./apiUser";
import { Redirect } from "react-router-dom";
import Footer from '../core/Footer';
import ReactGA from 'react-ga';

class Signup extends Component {
    constructor() {
        super();
        this.state = {
            name: "",
            lastName: '',
            companyName: '',
            companyType: '',
            email: "",
            password: "",
            error: "",
            success: false,
            open: false,
            recaptcha: false,
            terms: false,
            user: '',
            redirect: false
        };
    }

    adminMessage = ''

    componentDidMount() {
        ReactGA.initialize('UA-331790-15');
        ReactGA.pageview('/signup');
    };

    handleChange = name => event => {
        if (name === 'terms') {
            this.setState({ error: false, [name]: event.target.checked });
        }
        this.setState({ error: "" });
        this.setState({ [name]: event.target.value });
    };

    recaptchaHandler = e => {
        this.setState({ error: "" });
        let userDay = e.target.value.toLowerCase();
        let dayCount;

        if (userDay === "sunday") {
            dayCount = 0;
        } else if (userDay === "monday") {
            dayCount = 1;
        } else if (userDay === "tuesday") {
            dayCount = 2;
        } else if (userDay === "wednesday") {
            dayCount = 3;
        } else if (userDay === "thursday") {
            dayCount = 4;
        } else if (userDay === "friday") {
            dayCount = 5;
        } else if (userDay === "saturday") {
            dayCount = 6;
        }

        if (dayCount === new Date().getDay()) {
            this.setState({ recaptcha: true });
            return true;
        } else {
            this.setState({
                recaptcha: false
            });
            return false;
        }
    };

    clickSubmit = event => {
        event.preventDefault();
        const { name, lastName, companyName, companyType, email, terms, password } = this.state;

        const user = {
            name,
            lastName,
            companyName,
            companyType,
            email: email.toLowerCase(),
            password,
            terms
        };
        // console.log(user);        
        if (this.state.recaptcha) {
            //alert(user.name + " : " + user.lastName + " : " + user.companyName + " : " + user.companyType + " : " + user.email + " : " + user.password)
            if (user.email.match('gmail')) {
                this.setState({
                    error: "We do not accept GMAIL addresses at this time. Please use your company Email address."
                });
            } else {
                
                signup(user).then(data => {
                    if (data.error) {
                        this.setState({ 
                            error: data.error 
                        });
                    } else {
                        this.adminMessage = {
                            "subject":  'Request to join',
                            "sender": { "email": `${email}`, "name": `${email}` },
                            "replyTo": { "email": "donotreply@sdsconsortium.com", "name": "donotreply@sdsconsortium.com" },
                            "to": [{ "name": "trevor@sdsconsortium.com", "email": "trevor@sdsconsortium.com" }],
                            "htmlContent": `<html><body>From user ${name}<p>from company: ${companyName}, 
                                has requested to join the SDSIC site with email: ${email}</p><p>As a <b>${companyType}
                                </b></p></body></html>`
                        }

                        // make request to api to send message
                        sendMessage(this.adminMessage).then(data => {
                            this.setState({
                                error: "",
                                name: "",
                                lastName: "",
                                companyName: "",
                                companyType: "",
                                email: "",
                                password: "",
                                terms: false,
                                recaptcha: false,
                                open: true,
                                redirect: true
                            });
                        });

                        const userMessage = {
                            "subject":  'Your SDSconsortium.com request',
                            "sender": { "email": "donotreply@sdsconsortium.com", "name": "donotreply@sdsconsortium.com" },
                            "replyTo": { "email": "donotreply@sdsconsortium.com", "name": "donotreply@sdsconsortium.com" },
                            "to": [{ "name": `${email}`, "email": `${email}` }],
                            "htmlContent": `<html><body>Hello <b>${name}</b>, <p>Thank you for requesting to join <b>SDSconsortium.com</b></p> <p>We are setting up your 
                            information now and when things are ready we will send you an email confirming your membership.</p> 
                            <p> Thank you!</p> <p>ps: Please be sure to check you junk or spam folders for emails. </p></body></html>`           
                        }

                        // Let the user know something is happening
                        sendMessage(userMessage)                    
                    }
                });
            }
        } else {
            this.setState({
                error: "What day is today? Please write a correct answer!"
            });
        }
    };

    signupForm = (name, lastName, companyName, companyType, email, password, terms, recaptcha) => (

        <form>
            <div className="form-group">
                <label className="text-muted">First Name</label>
                <input
                    onChange={this.handleChange("name")}
                    type="text"
                    className="form-control"
                    value={name}
                />
            </div>
            <div className="form-group">
                <label className="text-muted">Last Name</label>
                <input
                    onChange={this.handleChange("lastName")}
                    type="text"
                    className="form-control"
                    value={lastName}
                />
            </div>
            <div className="form-group">
                <label className="text-muted">Company Name</label>
                <input
                    onChange={this.handleChange("companyName")}
                    type="text"
                    className="form-control"
                    value={companyName}
                />
            </div>
            <div className="form-group">
                <label className="text-muted">Company Type</label>
                <select onChange={this.handleChange('companyType')} value={companyType} className="form-control">
                    <option value="" selected></option>
                    <option value="Utility">Utility Company</option>
                    <option value="Services">Engineering Services Provider</option>
                    <option value="Vendor">Vendor</option>
                </select>
            </div>
            <div className="form-group">
                <label className="text-muted">Email</label>
                <input
                    onChange={this.handleChange("email")}
                    type="email"
                    className="form-control"
                    value={email}
                />
            </div>
            <div className="form-group">
                <label className="text-muted">Password</label>
                <input
                    onChange={this.handleChange("password")}
                    type="password"
                    className="form-control"
                    value={password}
                />
            </div>
            <div className="form-group">
                <div className="checkbox">
                    <label><input type="checkbox" checked={terms} onChange={this.handleChange('terms')} />
                        I agree to the <a href="/privacystatement" >SDSIC privacy policies</a> and the <a href="/termsstatement" >SDSIC website Terms of Service</a></label>
                </div>
            </div>

            <div className="form-group">
                <label className="text-muted">
                    {recaptcha ? "Thanks. You got it!" : "What day is today?"}
                </label>

                <input
                    onChange={this.recaptchaHandler}
                    type="text"
                    className="form-control"
                />
            </div>

            <button
                onClick={this.clickSubmit}
                className="btn btn-raised btn-primary"
            >
                Submit
            </button>
        </form>
    );

    render() {
        if (this.state.redirect === true) {
            return <Redirect to="/signuppending" />;
        }
        const { name, lastName, companyName, companyType, email, password, terms, recaptcha, error } = this.state;
        return (
            <div className="container col-md-8 offset-md-2">
                <h2 className="mt-5 mb-5">Signup</h2>

                <div
                    className="alert alert-danger"
                    style={{ display: error ? "" : "none" }}
                >
                    {error}
                </div>

                <div
                    className="alert alert-info"
                    style={{ display: this.open ? "" : "none" }}
                >
                    New account is successfully created. Please{" "}
                    <Link to="/signin">Sign In</Link>.
                </div>

                <div className="alert alert-info" >
                    Please sign up to request access.  Registration is 100% free! <br /> <br />
                    Alternatively, you can now use your LinkedIn account to signup with! <br />
                    <a href="/signin" >Sign In</a>
                </div>

                {this.signupForm(name, lastName, companyName, companyType, email, password, terms, recaptcha)}
                <Footer />
            </div>
        );
    }
}

export default Signup;