/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
// import Posts from "../post/Posts";
import { Button, Form } from 'react-bootstrap';
import Footer from './Footer';
import { sendMessage } from "../group/apigroup";
import moment from "moment";
import TN_IMAGE from '../images/chatanooga.PNG';
import MESA_LOGO from '../images/Mesa-Associates-Transparent.png';
import ReactPlayer from "react-player"

class publicMesaEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            error: '',
            success: ''
        }
    }

    handleChange = name => event => {
        if (name === "email") {
            this.setState({ error: "" });
            this.setState({ responseTo: name, [name]: event.target.value });
        }
    };

    showError() {
        return (
            <div className="alert alert-danger" style={{ display: this.state.error ? "" : "none" }}>
                {this.state.error}
            </div>
        )
    };

    showSuccess() {
        return (
            <div className="alert alert-info" style={{ display: this.state.success ? "" : "none" }}>
                <h3>Your on the list!</h3>
                <p>Don't forget to check your inbox and your spam folder for updates from the SDSIC.</p>
                <p>We will use the email you've submitted to send you updates as we finalize the upcoming event.</p>
            </div>
        )
    };

    submitEmail = e => {
        const { email } = this.state;
        e.preventDefault();
        if (email === "") { this.setState({ error: "email required!" }) }

        if (email !== "") {
            const mailMessage = {
                to: 'trevor.scullion@automationforce.com',
                from: email,
                subject: "SDSIC 2022 FALL event list",
                Message: 'Please add ' + email + ' to the SDSIC 2022 FALL event list.',
                html: "<p>Please add " + email + " to the SDSIC 2022 FALL event list.</p>",
                dateSent: moment().format('YYYY-MM-DD[T00:00:00.000Z]')
            }
            // make request to api to send message
            sendMessage(mailMessage).then(data => {
                this.setState({
                    email: '',
                    success: true,
                });
            });

            const userMessage = {
                to: email,
                from: 'donotreply@sdsconsortium.com',
                subject: "SDSIC 2022 FALL event",
                Message: 'Your name is on the SDSIC 2022 FALL event list.',
                html: "<p>Your name is on the SDSIC 2022 FALL event list.</p><p>You will be kept up to date " +
                    "as we finalize the event details.</p><p>Please do not reply to this email.</p>",
                dateSent: moment().format('YYYY-MM-DD[T00:00:00.000Z]')
            }

            // make request to api to send message
            sendMessage(userMessage).then(data => { });
        };
    };


    render() {
        return (
            <>
                <div className="container-fluid">
                    <div className="row justify-content-md-center">
                        <div className="col-md-12">
                            <div className="jumbotron p-3 p-md-5 text-white rounded bg-dark">
                                <div className="row mb-2">
                                    <div className="col-md-12">
                                        <h4 className="display-5 font-italic">The SDSIC Spring 2025 Live Event</h4>
                                        <h4>April 28th to May 1st 2025</h4>

                                        <p className="lead my-3">We are excited to be holding our only 2025 event this spring in beautiful Chattanooga, Tennesssee, hosted by our
                                            incredible partner, Mesa Associates, Inc. </p>
                                        <p className="lead mb-0">There will be a lot of details sent out as we plan this event but for now, just save the dates below!</p>
                                    </div>

                                </div>
                            </div>

                            <main role="main" className="container-fluid">
                                <div className="row">
                                    <div className="col-md-5">
                                        <h2 className="blog-post-title">Save the Date!</h2>
                                        <p>
                                            We are expecting another huge turnout once again as we only host one live event each year
                                            so don't forget to save April 28th to May 1st and we'll send out notifications when our registration goes live!
                                        </p>
                                        <h2>Hosted by: <a href="https://www.mesainc.com/" target="_blank"><img src={MESA_LOGO} alt="Mesa Associates" width="275" /></a></h2>
                                        {/* <p>
                                            <h3 className="blog-post-title">The Venue</h3>
                                            <h5 className="mb-0">
                                                <a className="text-dark" href="https://kcconvention.com/" target="_blank">Kansas City Convention Center</a>                                               
                                            </h5>
                                            <br/>
                                            <p className="card-text mb-auto">
                                            301 West 13th Street, Suite 100<br />
                                            Kansas City, MO 64105<br />
                                            </p>
                                            <br/>
                                            
                                            
                                        </p>
                                        <p>
                                            Once you register your can take advantage of a special room rate. The block of rooms is limited so don't wait!
                                        </p> */}
                                    </div>
                                    <div className="col-md-7">

                                        <ReactPlayer
                                            url="https://youtu.be/rDJDQcq_vzE"
                                            playing="true"
                                            volume="0.5"
                                            width={650}                                           
                                            // loop="true"
                                            // light="true"
                                            // controls="true"
                                        />

                                    </div>
                                </div>
                                <br />
                                <hr />

                                {/* <div className="row">



                                    <div className="col-md-6">
                                        <h4 className="blog-post-title"><u>Daily Agenda</u></h4>
                                        <br />
                                        <h5>Monday April 22nd</h5>
                                        <ul>
                                            <li>General Session: 3-5pm</li>
                                            <li>Evening Sponsor: Hosted event</li>
                                        </ul>
                                        <h5>Tuesday April 23 rd</h5>
                                        <ul>
                                            <li>Breakfast: 8-9AM</li>
                                            <li>General Session: 9AM-12PM </li>
                                            <li>Lunch: 12-1PM</li>
                                            <li>Committee Breakout Sessions: 1-4PM</li>
                                            <li>Evening Sponsor: Hosted Event </li>
                                        </ul>
                                        <h5>Wednesday April 24 th</h5>
                                        <ul>
                                            <li>Breakfast: 8-9AM</li>                                            
                                            <li>Committee Breakout Sessions: 9AM-12PM</li>
                                            <li>Lunch: 12-1PM</li>
                                            <li>Committee Breakout Sessions: 1-4PM</li>
                                            <li>Evening Sponsor: Hosted Event </li>
                                        </ul>
                                        <h5>Thursday April 25 th</h5>
                                        <ul>
                                            <li>Breakfast: 8-9AM</li>                                            
                                            <li>Committee Breakout Sessions: 9AM-12PM</li>
                                            <li>Lunch: 12-1PM</li>                                            
                                            <li>General Session: 1-2PM</li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <div>
                                            <h2>Join Us!</h2>                                            
                                        </div>
                                        <br/>

                                        <p>
                                            You must become an SDSIC member to register for this event but don't worry, <u>its 100% free!</u>
                                            
                                        </p>
                                        <p>
                                            The Registration fee is only <b>$550</b> which covers all four days of conference meetings, meals, and all the
                                            night time events!
                                        </p>
                                        
                                        <p>Our early bird pricing ended on <b>March 1st 2024</b>.</p>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <a className="btn btn-lg btn-block btn-primary" href="/signup">Sign up for an SDSIC Membership here</a>
                                            </div>
                                        </div>
                                        <br/>
                                        <hr/>

                                        <div>
                                            <h2>Sponsor Us</h2>
                                        </div>
                                
                                           <br />
                                        
                                        
                                        <p>If you are interested in Sponsoring this event, check out our sponsorship page!</p>
                                     
                                        <div className="row">
                                            <div className="col-md-6">
                                                <a className="btn btn-lg btn-block btn-primary" width="100%" href="/kansasSponsorReg">Register as a Sponsor</a>
                                            </div>
                                        </div>
                                            <br/>
                                        <p>Or Download our event sponsorship prospectus below.</p>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <a className="btn btn-lg btn-block btn-success" href="/images/SDSIC%20Sponsorship%20Prospectus%202.9.24.pdf" target="_blank">Download sponsorship Prospectus </a>
                                            </div>
                                        </div>


                                        
                                    </div>


                                </div> */}
                            </main>



                        </div>

                    </div >

                </div >
                <br />
                <br />
                <br />
                <br />
                <Footer />
            </>
        )
    }
}

export default publicMesaEvent