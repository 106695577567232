//Get all events
export const getAllEvents = (token) => {
    return fetch(`${process.env.REACT_APP_API_URL}/allevents`, {
        method: "GET",
        mode: 'cors',
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getAllUsersTodate = (token) => {

    return fetch(`${process.env.REACT_APP_API_URL}/users`, {
        method: "GET",
        mode: 'cors',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getAllRegistrations = () => {
   
    return fetch(`${process.env.REACT_APP_API_URL}/eventregistrations`, {
        method: "GET",
        mode: 'cors',
        headers: {
           
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const getPresentationById =(presentationId, token) => {
    return fetch(`${process.env.REACT_APP_API_URL}/presentationsbyId/${presentationId}`, {
        method: "GET",
        mode: 'cors',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const getPresentationsByEvent =(eventId, sortfield, sortDirection, token) => {
    return fetch(`${process.env.REACT_APP_API_URL}/presentationsbyEvent/${eventId}/?sortfield=${sortfield}&sortdirection=${sortDirection}`, {
        method: "GET",
        mode: 'cors',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getEventById = (eventId, token) => {

    return fetch(`${process.env.REACT_APP_API_URL}/eventdetailsbyId/${eventId}`, {
        method: "GET",
        mode: 'cors',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getEventsForGroup = (groupId, token) => {

    return fetch(`${process.env.REACT_APP_API_URL}/events/eventsbygroup/${groupId}`, {
        method: "GET",
        mode: 'cors',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const addEventForGroup = (groupId, token, formData) => {
    return fetch(`${process.env.REACT_APP_API_URL}/event/create/${groupId}`, {
        method: "POST",
        mode: 'cors',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: formData
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const createDownloadLink = (token, fileInfo) => {

    return fetch(`${process.env.REACT_APP_API_URL}/file/downloadfile`, {
        method: "POST",
        mode: 'cors',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(fileInfo)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const createNewEvent = (token, groupId, userId, newEventId, eventInfo) => {
    // /file/create/:userId
    return fetch(`${process.env.REACT_APP_API_URL}/event/create/${userId}`, {
        method: "POST",
        mode: 'cors',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ eventInfo, groupId, userId, newEventId })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateEvent = (groupId, userId, event) => {

    return fetch(`${process.env.REACT_APP_API_URL}/event/update`, {
        method: "PUT",
        mode: 'cors',
        headers: {
            Accept: "application/json",
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ event, userId, groupId })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const removeEvent = (token, eventId) => {
    return fetch(`${process.env.REACT_APP_API_URL}/event/delete`, {
        method: "DELETE",
        mode: 'cors',
        headers: {
            Accept: "application/json",
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ eventId })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const registerUser = async (body, eventId) => {
    return fetch(`${process.env.REACT_APP_API_URL}/eventregistration/${eventId}`, {
        method: "POST",
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};



export const registerSponsor = (body, eventId) => {
    return fetch(`${process.env.REACT_APP_API_URL}/eventsponsor/${eventId}`, {
        method: "POST",
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const submitSurvey = (body, eventId) => {
    return fetch(`${process.env.REACT_APP_API_URL}/submitsurvey/${eventId}`, {
        method: "POST",
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}


export const createSchedule = (body) => {
    return fetch(`${process.env.REACT_APP_API_URL}/eventschedule`, {
        method: "POST",
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}


//Get all events
export const getSchedule = (eventName, token) => {
    return fetch(`${process.env.REACT_APP_API_URL}/getschedule`, {
        method: "POST",
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ eventName: eventName })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const updateSchedule = (body) => {

    return fetch(`${process.env.REACT_APP_API_URL}/updateschedule`, {
        method: "PUT",
        mode: 'cors',
        headers: {
            Accept: "application/json",
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


//Payment Checkout
export const checkOut = (body) => {
    return fetch(`${process.env.REACT_APP_API_URL}/checkoutpay`, {
        method: "POST",
        mode: 'cors',
        headers: {
            Accept: "application/json",
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};